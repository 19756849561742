/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from "react";
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import { PersonPinOutlined, ArrowBack } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from 'react-router-dom';
import { UserClient } from "services/api";
import { customMarkers, createMarkers, createUserMarkers } from "./utils";

const containerStyle = {
  width: '100%',
  height: '750px'
};

const GoogleMapsComponent = (): JSX.Element => {

  const { t } = useTranslation();
  const { userId } = useParams();
  const history = useHistory();
  const { getUserAll, getUserById } = UserClient();
  const TITLE = !userId ? t("google_maps") : t('map_of_user');
  const [markers, setMarkers] = useState<any>(customMarkers);
  const [userProfile, setUserProfile] = useState<any>();
  const [activeMarker, setActiveMarker] = useState<number>(-1);
  const [centerPosition, setCenterPosition] = useState<{lat: number, lng: number}>({ lat: 40.7929, lng: 43.8465 });

  useEffect(() => {
    (async () => {
      if (userId) {
        const user = await getUserById(userId);
        setUserProfile(user);
        const userMarkers = createUserMarkers(user, t);
        if (userMarkers?.length){
          setMarkers(userMarkers);
          setCenterPosition(userMarkers[0].position);
        }
      } else {
        const users = await getUserAll();
        const userMarkers = createMarkers(users, t);
        if (userMarkers?.length) setMarkers(userMarkers);
      }
    })();
  }, [])

  const handleActiveMarker = (markerId: number) => {
    if (markerId === activeMarker) {
      return;
    }
    setActiveMarker(markerId);
  };

  const handleOnLoad = (map: any) => {
    const bounds = new google.maps.LatLngBounds();
    markers.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  const goToUserMap = (userId: number) => history.push(`/map/${userId}`);

  return (
    <div>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          {userProfile && <ArrowBack fontSize="medium" style={{cursor: 'pointer', marginLeft: '10px'}} onClick={()=> window.open('/map', '_self')} />}
          <h1 style={{padding: '10px'}}>{TITLE}</h1>
        </div>
        <div style={userProfile ? {position: 'absolute', left: '50%'} : {cursor: 'pointer'}}>
          {userProfile ?
            <a href={`/user/details/${userId}`} style={{fontSize: '20px', fontWeight: 'bold'}}>{`${userProfile.firstName} ${userProfile.lastName}`}</a> :
            <span style={{marginRight: '15px'}}>
              {<PersonPinOutlined fontSize="large" />}
            </span>
          }
        </div>
      </div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={centerPosition}
        zoom={10}
        onLoad={handleOnLoad}
      >
        {markers.map(({ id, userId, fio, avatar, accountType, lastModified, position }) => (
          <Marker
          key={id}
          position={position}
          onClick={() => handleActiveMarker(id)}
        >
          {activeMarker === id ? (
            <InfoWindow onCloseClick={() => setActiveMarker(-1)}>
              <>
                <img src={avatar} alt="avatar" width="100" height="100"></img>
                <p><b>{t('fio') + ': '}</b><span style={{cursor:'pointer'}} onClick={() => window.open(`/user/details/${userId}`)}>{fio}</span></p>
                <p><b>{t('role') + ': '}</b><span>{accountType}</span></p>
                <p><b>{t('last_activation') + ': '}</b><span>{lastModified}</span></p>
                <p><b>{t('last_geoData') + ': '}</b><span style={{cursor:'pointer', textDecoration: 'underline'}} onClick={() => goToUserMap(userId)}>{t('link')}</span></p>
              </>
            </InfoWindow>
          ) : null}
        </Marker>
        ))}
      </GoogleMap>
    </div>
  );
}

export default GoogleMapsComponent;
