import moment from "moment";

export const returnBaggageType = (type: string, data: Array<any>, checkIsFirst): any => {
  if (data && data.length > 0) {
    const filteredData = data.filter((element) => type === element.name);
    if (filteredData.length > 0) {
      return `${filteredData[0].synonym}`;
    }
    return `${checkIsFirst ? '' : ', '} ${type}`;
  } else {
    return `${checkIsFirst ? '' : ', '} ${type}`;
  }
};

export const getVerificationStatusText = (status: string) => {
  switch (status) {
    case 'NotVerified':
      return 'not_verified';
    case 'Pending':
      return 'verification_pending';
    case 'Verified':
      return 'verified';
    case 'VerificationFailure':
      return 'verification_failure';
    default: return 'unknown'
  }
}

export const setRowBackColor = (created: any) => {
  return moment().diff(moment(created, "DD.MM.YYYY HH:mm"), "hours") > 24 ? '#a0dbb2' : '#d1aca7';
}
