/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, FC, useMemo } from 'react';
import MUIDataTable from 'mui-datatables';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import { StarOutlineOutlined, Star } from '@material-ui/icons';
import { GET_COLUMN, OPTIONS, getTransportByName } from './utils';
import { TripClient, PlaceDetails } from '../../services/api';
import { setRowBackColor } from 'models/util';
import { createSortedTripListFromRowData } from '../../models/modelFactory/trip';
import { AppContextType, useApp } from '../../context/AppContext';
import useQuery from '../../hooks/useQuery';


const TripComponent: FC = () => {
  const { t } = useTranslation();
  const TITLE = t('trips');
  const [data, setData] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalRaws, setTotalRaws] = useState<number>(0);
  const [transportType, setTransportType] = useState<string>();
  const [actual, setActual] = useState<string>();
  const [paymentStatus, setPaymentStatus] = useState<number>();
  const [createdOnFrom, setCreatedOnFrom] = useState<number>();
  const [createdOnTo, setCreatedOnTo] = useState<number>();
  const [startDateFrom, setStartDateFrom] = useState<number>();
  const [startDateTo, setStartDateTo] = useState<number>();
  const [endDateFrom, setEndDateFrom] = useState<number>();
  const [endDateTo, setEndDateTo] = useState<number>();
  const [fromPlaceId, setFromPlaceId] = useState<string>();
  const [toPlaceId, setToPlaceId] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { getPaginatedTrips, changeActualTrip } = TripClient();
  const { getPlaceDetails } = PlaceDetails();
  const { baggages } = useApp() as AppContextType;
  const query = useQuery();
  const page = parseInt(queryParams.get('page') as string) || 1;
  const pageSize = parseInt(queryParams.get('pageSize') as string) || 10;

  useEffect(() => {
    (async () => {
      const { items, total, totalPages} = await getPaginatedTrips(
        page, pageSize, undefined, {transportType, actual, paymentStatus, createdOnFrom, createdOnTo, startDateFrom, startDateTo, endDateFrom, endDateTo, fromPlaceId, toPlaceId, phoneNumber}
      );
      const parsedData = items ? createSortedTripListFromRowData(items, baggages) : [];
      const locationFromData = await Promise.all(parsedData.map(trip => trip.fromPlaceId && getPlaceDetails(trip.fromPlaceId)));
      const locationToData = await Promise.all(parsedData.map(trip => trip.toPlaceId && getPlaceDetails(trip.toPlaceId)));
      const localizedData = parsedData.map(trip => {
        const locationFrom = locationFromData.find(item => item?.placeId === trip.fromPlaceId);
        const locationTo = locationToData.find(item => item?.placeId === trip.toPlaceId);
        return {
          ...trip,
          addressFrom: locationFrom?.name ?? trip.addressFrom,
          addressTo: locationTo?.name ?? trip.addressTo
        }
      })
      setTotalRaws(total);
      setTotalPages(totalPages);
      setData(localizedData);
    })();
  }, [baggages, page, pageSize, transportType, actual, paymentStatus, createdOnFrom, createdOnTo, startDateFrom, startDateTo, endDateFrom, endDateTo, fromPlaceId, toPlaceId, phoneNumber]);

  const onChangePage = (currentPage: number) => {
    history.push(`?page=${currentPage + 1}&pageSize=${pageSize}`);
  };

  const queryObject: any = query.getObject();

  const isActualColumn = {
    name: 'isActual',
    label: t('isActual'),
    options: {
      filter: true,
      display: true,
      customFilterListOptions: {
        render: (v) => {
          if (v) {
            return t('isActual');
          } 
          return false;
        },
      },
      filterType:"checkbox" as any,
      filterOptions: {
        names: [true],
        logic(active, filterVal, row) {
          return !row[0];
        },
        renderValue: val => {
          if (val === true) {
            return t('yes');
          }
        }
      },
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <IconButton
            onClick={async () => {
              const change = await changeActualTrip(tableMeta.rowData[4], {isActual: !value});
              change && setData(data => {
                const newData = [...data];
                newData[tableMeta.rowIndex].isActual = change.isActual;
                return newData;
              });
            }}>
            {value ? <Star /> : <StarOutlineOutlined />}
          </IconButton>
        );
      }
    }
  };
  const column = useMemo(() => GET_COLUMN(queryObject, t), []);

  return (
    <React.Fragment>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '30px 0' }}>
        <div style={{ width: '90%' }}>
          <MUIDataTable
            title={TITLE}
            data={data}
            columns={[isActualColumn, ...column]}
            options={{
              ...OPTIONS(t),
              onFilterChange(changedColumn: string, filterList, type, changedColumnIndex) {
                switch (changedColumn) {
                  case 'phone':
                    query.delete('phone');
                    if (filterList[changedColumnIndex][0]) {
                      query.set('phone', filterList[changedColumnIndex][0])
                      setPhoneNumber(filterList[changedColumnIndex][0]);
                    } else {
                      query.delete('phone');
                      setPhoneNumber(undefined);
                    }
                  break;
                  case 'sum': {
                    query.delete('sumMin');
                    query.delete('sumMax');

                    if (filterList[changedColumnIndex][0]) {
                      query.set('sumMin', filterList[changedColumnIndex][0]);
                    }

                    if (filterList[changedColumnIndex][1]) {
                      query.set('sumMax', filterList[changedColumnIndex][1]);
                    }
                    break;
                  }
                  case 'offerSum': {
                    query.delete('offerSumMin');
                    query.delete('offerSumMax');

                    if (filterList[changedColumnIndex][0]) {
                      query.set('offerSumMin', filterList[changedColumnIndex][0]);
                    }

                    if (filterList[changedColumnIndex][1]) {
                      query.set('offerSumMax', filterList[changedColumnIndex][1]);
                    }
                    break;
                  }
                  case 'createdOn': {
                    query.delete('createdOnStart');
                    query.delete('createdOnEnd');

                    if (filterList[changedColumnIndex][0]) {
                      query.set('createdOnStart', filterList[changedColumnIndex][0]);
                      setCreatedOnFrom(moment(filterList[changedColumnIndex][0]).valueOf());
                    } else {
                      query.delete('createdOnStart');
                      setCreatedOnFrom(undefined);
                    }

                    if (filterList[changedColumnIndex][1]) {
                      query.set('createdOnEnd', filterList[changedColumnIndex][1]);
                      setCreatedOnTo(moment(filterList[changedColumnIndex][1]).valueOf());
                    } else {
                      query.delete('createdOnEnd');
                      setCreatedOnTo(undefined);
                    }
                    break;
                  }
                  case 'startDate': {
                    query.delete('startDateOnStart');
                    query.delete('startDateOnEnd');

                    if (filterList[changedColumnIndex][0]) {
                      query.set('startDateOnStart', filterList[changedColumnIndex][0]);
                      setStartDateFrom(moment(filterList[changedColumnIndex][0]).valueOf());
                    } else {
                      query.delete('startDateOnStart');
                      setStartDateFrom(undefined);
                    }

                    if (filterList[changedColumnIndex][1]) {
                      query.set('startDateOnEnd', filterList[changedColumnIndex][1]);
                      setStartDateTo(moment(filterList[changedColumnIndex][1]).valueOf());
                    } else {
                      query.delete('startDateOnEnd');
                      setStartDateTo(undefined);
                    }
                    break;
                  }
                  case 'endDate': {
                    query.delete('endDateOnStart');
                    query.delete('endDateOnEnd');

                    if (filterList[changedColumnIndex][0]) {
                      query.set('endDateOnStart', filterList[changedColumnIndex][0]);
                      setEndDateFrom(moment(filterList[changedColumnIndex][0]).valueOf());
                    } else {
                      query.delete('endDateOnStart');
                      setEndDateFrom(undefined);
                    }

                    if (filterList[changedColumnIndex][1]) {
                      query.set('endDateOnEnd', filterList[changedColumnIndex][1]);
                      setEndDateTo(moment(filterList[changedColumnIndex][1]).valueOf());
                    } else {
                      query.delete('endDateOnEnd');
                      setEndDateTo(undefined);
                    }
                    break;
                  }
                  case 'transportType': {
                    query.delete('transport');
                    if (filterList[changedColumnIndex][0]) {
                      query.set('transport', filterList[changedColumnIndex][0]);
                      setTransportType(getTransportByName(filterList[changedColumnIndex][0], t));
                    } else {
                      query.delete('transport');
                      setTransportType(undefined);
                    }
                    break;
                  }
                  case 'isActual': {
                    query.delete('isActual');
                    if (filterList[changedColumnIndex][0]?.toString()) {
                      query.set('isActual', filterList[changedColumnIndex][0].toString());
                      setActual(filterList[changedColumnIndex][0].toString());
                    } else setActual(undefined);
                    break;
                  }
                  case 'payment': {
                    query.delete('payment');
                    if (filterList[changedColumnIndex][0]) {
                      const type = filterList[changedColumnIndex][0] === t('not_done') ? 0 : 1;
                      query.set('payment', filterList[changedColumnIndex][0]);
                      setPaymentStatus(type);
                    } else {
                      query.delete('payment');
                      setPaymentStatus(undefined);
                    }
                    break;
                  }
                  case 'addressFrom': {
                    query.delete('addressFrom');
                    if (filterList[changedColumnIndex][0]) {
                      const location = filterList[changedColumnIndex][0] as any;
                      query.set('addressFrom', location.name.split(',')[0]);
                      setFromPlaceId(location.place_id);
                    } else {
                      query.delete('addressFrom');
                      setFromPlaceId(undefined);
                    }
                    break;
                  }
                  case 'addressTo': {
                    query.delete('addressTo');
                    if (filterList[changedColumnIndex][0]) {
                      const location = filterList[changedColumnIndex][0] as any;
                      query.set('addressTo', location.name.split(',')[0]);
                      setToPlaceId(location.place_id);
                    } else {
                      query.delete('addressTo');
                      setToPlaceId(undefined);
                    }
                    break;
                  }
                  default:
                    if (changedColumn) {
                      query.delete(changedColumn);
                      if (filterList[changedColumnIndex][0]) {
                        query.set(changedColumn, filterList[changedColumnIndex][0]);
                      }
                    } else {
                      query.delete('transport');
                      query.delete('isActual');
                      query.delete('payment');
                      query.delete('createdOnStart');
                      query.delete('createdOnEnd');
                      query.delete('startDateOnStart');
                      query.delete('startDateOnEnd');
                      query.delete('endDateOnStart');
                      query.delete('endDateOnEnd');
                      query.delete('addressFrom');
                      query.delete('addressTo');
                      query.delete('phone');
                      setTransportType(undefined);
                      setActual(undefined);
                      setPaymentStatus(undefined);
                      setCreatedOnFrom(undefined);
                      setCreatedOnTo(undefined);
                      setStartDateFrom(undefined);
                      setStartDateTo(undefined);
                      setEndDateFrom(undefined);
                      setEndDateTo(undefined);
                      setFromPlaceId(undefined);
                      setToPlaceId(undefined);
                      setPhoneNumber(undefined);
                    }
                    break;
                }
                history.push(`?${query.toString()}`);
              },
              setRowProps: (row) => {
                return {
                  onDoubleClick: () => {
                    history.push(`/trip/details/${row[4]}`);
                  },
                  style: {
                    background: setRowBackColor(row[2])
                  }
                };
              },
              serverSide: true,
              onChangeRowsPerPage: (numberOfRows) => {
                history.push(`?page=1&pageSize=${numberOfRows}`);
              },
              rowsPerPage: pageSize,
              page: page - 1,
              onChangePage,
              pagination: totalPages > 1,
              rowsPerPageOptions: [5, 10, 25, 50, 100],
              count: totalRaws
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default TripComponent;
