/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MUIDataTable from 'mui-datatables';
import { Tabs, Tab } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { createReviewsFromData, createUserDetail, OPTIONS, COLUMN_ONE, COLUMN_TWO } from './utils';
import UserParcelList from '../UserComponent/UserParcelList/UserParcelList';
import UserTripList from '../UserComponent/UserTripList/UserTripList';
import { BlackList, CarsClient, ParcelClient, TripClient, UserClient, RatingApi, PlaceDetails } from '../../services/api';
import moment from 'moment';
import { TabContext, TabPanel } from '@material-ui/lab';
import { createSortedTripListFromRowData } from 'models/modelFactory/trip';
import { AppContextType, useApp } from 'context/AppContext';
import { createParcelsListFromRowData } from 'models/modelFactory/parcel';
import ReviewComponent from './components/reviews/Reviews';
import CarsComponent from 'components/CarsComponent/CarsComponent';
import DialogComponent from './DialogComponent';
import DefaultNotice from 'components/NoticeComponent';

const UserDetail = function (): JSX.Element {
  const { t } = useTranslation();

  const title = t('user_information');
  const { id } = useParams();
  const { changeRating, deleteRating, getPaginatedRatings } = RatingApi();
  const { getPlaceDetails } = PlaceDetails();
  const { getUserById, changeUserDetail, getRatingByUser } = UserClient();
  const [tripList, setTripList] = useState<any>([]);
  const [parcelList, setParcelList] = useState<any>([]);
  const [data, setData] = useState<any[]>([]);
  const [cars, setCars] = useState<any>([]);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [secondName, setSecondName] = useState<string>('');
  const [firstName, setFirstname] = useState<string>('');
  const [successTripsCount, setSuccessTripsCount] = useState<string>('');
  const [successParcelsCount, setSuccessParcelsCount] = useState<string>('');
  const [thirdName, setThirdName] = useState<string>('');
  const [birthday, setBirthday] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [userType, setUserType] = useState<any>('');
  const [open, setOpen] = useState(false);

  const [totalTripPages, setTotalTripPages] = useState<number>(1);
  const [tripTotal, setTripTotal] = useState<number>(0);
  const [tripPage, setTripPage] = useState<number>(0);
  const [tripPageSize, setTripPageSize] = useState<number>(10);

  const [totalParcelPages, setTotalParcelPages] = useState<number>(1);
  const [parcelTotal, setParcelTotal] = useState<number>(0);
  const [parcelPage, setParcelPage] = useState<number>(0);
  const [parcelPageSize, setParcelPageSize] = useState<number>(10);

  const [totalReviewPages, setTotalReviewPages] = useState<number>(1);
  const [reviewTotal, setReviewTotal] = useState<number>(0);
  const [reviewPage, setReviewPage] = useState<number>(0);
  const [reviewPageSize, setReviewPageSize] = useState<number>(10);

  const [totalCarPages, setTotalCarPages] = useState<number>(1);
  const [carTotal, setCarTotal] = useState<number>(0);
  const [carPage, setCarPage] = useState<number>(0);
  const [carPageSize, setCarPageSize] = useState<number>(10);

  const [url, setUrl] = useState<string>(
    'https://upload.wikimedia.org/wikipedia/commons/2/2c/Default_pfp.svg'
  );
  const [tabValue, setTabValue] = React.useState<string>('1');
  const { getPaginatedTrips } = TripClient();
  const { addUserToBlackList ,deleteBlackListById} = BlackList();
  const { baggages } = useApp() as AppContextType;
  const { getPaginatedParcels } = ParcelClient();
  const optionValues = [t('shifter'), t('sender'), t('admin')];
  const [change, setChange] = useState<boolean>(false);
  const [reviews, setReviews] = useState<any>([]);
  const handleTabChange = (event: any, newValue: string) => setTabValue(newValue);
  const [isVisable, setIsVisable] = useState<boolean>(false);
  const [resason, setReason] = useState<string>('');
  const { getPaginatedCars } = CarsClient();

  const setValueByRowIndex = (index: number, valueTextField: string) => {
    switch (index) {
      case 0:
        setFirstname(valueTextField);
        break;
      case 1:
        setSecondName(valueTextField);
        break;
      case 2:
        setThirdName(valueTextField);
        break;
      case 3:
        setPhoneNumber(valueTextField);
        break;
      case 4:
        setBirthday(valueTextField);
        break;
      case 5:
        setEmail(valueTextField);
        break;

      default:
        break;
    }
  };

  const setValueByRowIndexForTwo = (index: number, valueTextField: string) => {
    switch (index) {
      case 3:
        setSuccessTripsCount(valueTextField);
        break;
      case 4:
        setSuccessParcelsCount(valueTextField);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    (async () => {
      const tripData = await getPaginatedTrips(tripPage + 1, tripPageSize, id);
      const reviewData = await getPaginatedRatings(reviewPage + 1, reviewPageSize, id);
      const carData = await getPaginatedCars(carPage + 1, carPageSize, id);
      setCars(carData?.items ?? []);
      const parsedTripData = tripData
        ? createSortedTripListFromRowData(tripData.items, baggages)
        : [];
      setTripList(await getLocalizedData(parsedTripData) ?? []);
      const parcelData = await getPaginatedParcels(parcelPage + 1, parcelPageSize, id);
      const parsedParcelData = parcelData
        ? createParcelsListFromRowData(parcelData.items, baggages, t)
        : [];
      setParcelList(await getLocalizedData(parsedParcelData) ?? []);
      const detailUser = await getUserById(id);
      detailUser?.avatarUrl && setUrl(detailUser?.avatarUrl);
      setUserType(detailUser.accountType);
      setPhoneNumber(detailUser.phone);
      setSuccessTripsCount(detailUser.successTripsCount);
      setSuccessParcelsCount(detailUser.successParcelsCount);
      setSecondName(detailUser.lastName);
      setFirstname(detailUser.firstName);
      setThirdName(detailUser.middleName);
      setBirthday(moment(detailUser.birthdayTS).format('DD.MM.YYYY').toString());
      setEmail(detailUser.email);
      setData(createUserDetail(detailUser, t) ?? []);
      setReviews(createReviewsFromData(reviewData?.ratings) ?? []);
      setTripTotal(tripData?.total ?? 0);
      setTotalTripPages(tripData?.totalPages);
      setParcelTotal(parcelData?.total ?? 0);
      setTotalParcelPages(parcelData?.totalPages);
      setReviewTotal(reviewData?.total ?? 0);
      setTotalReviewPages(reviewData?.totalPages);
      setCarTotal(carData?.items?.length ?? 0);
      setTotalCarPages(carData?.totalPages);
    })();
  }, [id, url, baggages, t, tripPage, tripPageSize, parcelPage, parcelPageSize, reviewPage, reviewPageSize, carPage, carPageSize]);
   
  const unlock=async()=>{
    await deleteBlackListById(id);
    window.location.reload()

  }
  const changeData = (value: boolean) => {
    const array = [...data];
    for (let index = 0; index < array.length; index++) {
      array[index].isChanged = value;
    }
    setData(array);
  };
  const getLocalizedData = async (data) => {
    const fromData = await Promise.all(data.map(item => getPlaceDetails(item.fromPlaceId)));
    const toData = await Promise.all(data.map(item => getPlaceDetails(item.toPlaceId)));
    return data.map((elem: any) => {
      const locationFrom = fromData.find(item => item.placeId === elem.fromPlaceId)
      const locationTo = toData.find(item => item.placeId === elem.toPlaceId)
      return {
        ...elem,
        addressFrom: locationFrom?.fullAddress,
        addressTo: locationTo?.fullAddress,
      }
    });
  }
  const saveChangeUserDetail = async () => {
    const data = {
      id: parseInt(id),
      phone: phoneNumber,
      lastName: secondName,
      firstName: firstName,
      middleName: thirdName,
      birthdayTS: moment(birthday).unix() * 1000,
      email: email,
      accountType: userType,
      successTripsCount: parseInt(successTripsCount),
      successParcelsCount: parseInt(successParcelsCount)
    };
    const detailUser = await changeUserDetail(parseInt(id), data);
    setData(createUserDetail(detailUser, t));
  };
  const addToBlackList = async () => {
    const data = {
      reason: resason,
      p2PUserId: id,
      deviceID: "Web"
    }
    await addUserToBlackList(data);
    setOpen(true);
    setIsVisable(false);
  }
  const dataTable = useMemo(
    () => (
      <MUIDataTable
        title={''}
        data={data.slice(0, 10)}
        columns={COLUMN_ONE(optionValues, setUserType, setValueByRowIndex, id, t)}
        options={{
          ...OPTIONS(t)
        }}
      />
    ),
    [data, url]
  );
  const dataTableTwo = useMemo(
    () => (
      <MUIDataTable
        title={''}
        data={data.slice(10, data.length)}
        columns={COLUMN_TWO(id, setUserType, setValueByRowIndexForTwo, t)}
        options={{
          ...OPTIONS(t)
        }}
      />
    ),
    [data, url]
  );
  const changeReview = async (data) => {
    await changeRating(data);
    const reviews = await getRatingByUser(id);
    setReviews(createReviewsFromData(reviews))
  }
  const deleteReview = async (id) => {
    await deleteRating(id);
    const reviews = await getRatingByUser(id);
    setReviews(createReviewsFromData(reviews))
  }
  return (
    <React.Fragment>
      <div style={{}}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '20px'
          }}>
          <img
            src={url}
            style={{ width: '100px', height: '100px', borderRadius: '50%', marginRight: '10px' }}
            alt={'avatar'}
          />
          <h1>{title}</h1>
        </div>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <div style={{ width: '40%', marginRight: '2%' }}>{dataTable}</div>
          <div style={{ width: '40%', marginLeft: '2%' }}>{dataTableTwo}</div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
          <div style={{ margin: '5px' }}>
            <Button variant='outlined' onClick={() => { setChange(!change); changeData(!change) }}>
              {change ? t('cancel') : t('change')}
            </Button>
          </div>
          <div style={{ margin: '5px' }}>
            <Button variant='outlined' onClick={() => { saveChangeUserDetail(); setChange(!change)}}>
              {t('save')}
            </Button>
          </div>
          <div style={{ margin: '5px' }}>
            <Button variant='outlined' onClick={() => {data.length>0&&!data[11]?.data ?setIsVisable(true):unlock() }}>
              {data.length > 0 && data[11]?.data ? t('unlock') : t('add_to_black_list')}
            </Button>
          </div>
        </div>

        <DialogComponent
          open={isVisable}
          handleClose={() => {
            setIsVisable(false);
          }}
          reason={resason}
          title={t('reason')}
          setReason={setReason}
          add={addToBlackList}
        />
      </div>
      <div style={{ width: '100%', margin: '30px' }}>
        <TabContext value={tabValue}>
          <Tabs value={tabValue} onChange={handleTabChange} textColor='primary' indicatorColor='primary'>
            <Tab value='1' label={`${t('trips')} (${tripTotal})`} />
            <Tab value='2' label={`${t('parcels')} (${parcelTotal})`} />
            <Tab value='3' label={`${t('reviews')} (${reviewTotal})`} />
            <Tab value='4' label={`${t('cars')} (${carTotal})`} />
          </Tabs>
          <TabPanel value='1'>
            <UserTripList
              tripList={tripList}
              paginationData={
                {
                  page: tripPage,
                  setPage: setTripPage,
                  pageSize: tripPageSize,
                  setPageSize: setTripPageSize,
                  total: tripTotal,
                  totalPages: totalTripPages
                }
              }
            />
          </TabPanel>
          <TabPanel value='2'>
            <UserParcelList
            data={parcelList}
            paginationData={
              {
                page: parcelPage,
                setPage: setParcelPage,
                pageSize: parcelPageSize,
                setPageSize: setParcelPageSize,
                total: parcelTotal,
                totalPages: totalParcelPages
              }
            }
          />
          </TabPanel>
          <TabPanel value='3'>
            <ReviewComponent
              data={reviews}
              changeReview={changeReview}
              deleteReview={deleteReview}
              paginationData={
                {
                  page: reviewPage,
                  setPage: setReviewPage,
                  pageSize: reviewPageSize,
                  setPageSize: setReviewPageSize,
                  total: reviewTotal,
                  totalPages: totalReviewPages
                }
              }
            />
          </TabPanel>
          <TabPanel value='4'>
            <CarsComponent
              data={cars}
              paginationData={
                {
                  page: carPage,
                  setPage: setCarPage,
                  pageSize: carPageSize,
                  setPageSize: setCarPageSize,
                  total: carTotal,
                  totalPages: totalCarPages
                }
              }
            />
          </TabPanel>
        </TabContext>
        {open && <DefaultNotice />}

      </div>
    </React.Fragment>
  );
};

export default UserDetail;
