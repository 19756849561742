/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TabContext, TabPanel } from '@material-ui/lab';
import { Button, Tab, Tabs } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { COLUMN, OPTIONS, createVerificationTripRequestDetail } from './utils';
import { Verification } from '../../services/api';
import { ShifterImages } from 'components/ParcelDetail/ShifterImages';
import DialogComponent from 'components/UserDetail/DialogComponent';
import { getVerificationStatusText } from 'models/util';

const VerificationTripRequestDetail = function (): JSX.Element {

  enum vfStatus {
    notVerified = 'NotVerified',
    pending = 'VerificationPending',
    verified = 'Verified',
    failure = 'VerificationFailure'
  }

  const { t } = useTranslation();
  const title = t('detail_verification');
  const { id } = useParams();
  const { getVerificationTripById, changeVerificationTripById } = Verification();
  const [data, setData] = useState<any[]>([]);
  const [tabValue, setTabValue] = React.useState('1');
  const [images, setImages] = useState([]);
  const [isVisable, setIsVisable] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');
  const [language, setLanguage] = useState<string>('ru');
  const handleTabChange = (event: any, newValue: string) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    (async () => {
      const tripById = await getVerificationTripById(id);
      tripById.verificationStatus = t(getVerificationStatusText(tripById.verificationStatus));
      const deatil = createVerificationTripRequestDetail(tripById, t);
      setImages(tripById.imageUrls);
      setLanguage(tripById.language);
      setData(deatil);
    })();
  }, [t]);

  const changeConfirm = async (value: boolean, status: string) => {
    const data = {
      isReviewed: value,
      comment : comment,
      verificationStatus: status
    }
    await changeVerificationTripById(id, data);
    setComment('');
    setIsVisable(false);
    window.location.reload();
  }

  return (
    <React.Fragment>
      <h1 style={{ textAlign: 'center' }}>{title}</h1>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '20px' }}>
        <div style={{ width: '40%', marginRight: '2%' }}>
          <MUIDataTable
            title={''}
            data={data.slice(0, 9)}
            columns={COLUMN()}
            options={OPTIONS(t)}
          />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
        <div style={{ margin: '5px' }}>
          <Button variant='outlined' style={{backgroundColor:'#008000'}} onClick={() => { changeConfirm(true, vfStatus.verified) }} >
            {t('confirm')}
          </Button>
        </div>
        <div style={{ margin: '5px' }}>
          <Button variant='outlined' style={{backgroundColor:'#c70000'}} onClick={() => { setIsVisable(true) }}>
            {t('no_confirm')}
          </Button>
        </div>
      </div>
      <DialogComponent
        open={isVisable}
        handleClose={() => {
          setIsVisable(false);
        }}
        reason={comment}
        title={t('reason')}
        setReason={setComment}
        add={()=>changeConfirm(false, vfStatus.failure) }
        language={language}
      />
      <div style={{ width: '100%', margin: '30px' }}>
        <TabContext value={tabValue}>
          <Tabs value={tabValue} onChange={handleTabChange} textColor='primary' indicatorColor='primary'>
            <Tab value='1' label={`${t('pictures')}`} />
          </Tabs>
          <TabPanel value='1'>
            <ShifterImages list={images} />
          </TabPanel>
        </TabContext>
      </div>
    </React.Fragment>
  );
};

export default VerificationTripRequestDetail;
