/* eslint-disable import/no-extraneous-dependencies */

import moment from "moment-timezone";
import UserModel from '../../models/user';

type userResponseModel = {
  active: boolean;
  birthday: string;
  claims: Array<any>;
  createdOn: string;
  documents: Array<any>;
  email: string;
  emailConfirmed: boolean;
  fcmToken: string;
  firstName: string;
  id: string;
  isNew: boolean;
  password: string;
  phoneNumber: string;
  phoneNumberConfirmed: boolean;
  lastName: string;
  smsCode: string;
  middleName: string;
  phone: string;
  createdOnTS:number;
  birthdayTS:number
  accountType:string;
  deviceType:string;
};

const createUserFromRowData = (responseItem: userResponseModel): UserModel => {
  return new UserModel({
    phone: responseItem.phone,
    firstName: responseItem.firstName,
    lastName: responseItem.lastName,
    middleName: responseItem.middleName,
    createdOn: moment(responseItem.createdOnTS).format('DD.MM.YYYY HH:mm').toString(),
    birthday: moment(responseItem.birthdayTS).format('DD.MM.YYYY').toString(),
    email: responseItem.email,
    id: responseItem.id,
    role: responseItem?.accountType,
    deviceType: responseItem?.deviceType,
  });
};

export const createUserListFromRowData = (response: Array<userResponseModel>): Array<UserModel> => {
  return response.map((item: userResponseModel) => {
    return createUserFromRowData(item);
  });
};

export const createSortedUserListFromRowData = (
  response: Array<userResponseModel>
): Array<UserModel> => {
  return createUserListFromRowData(response).sort((a, b) => {
    const x = moment(a.createdOn, "DD-MM-YYYY");
    const y = moment(b.createdOn, "DD-MM-YYYY");
    return y.diff(x);
  });
};

export default createSortedUserListFromRowData;
