import moment from 'moment';
import {
  Checkbox,
  FormGroup,
  FormLabel,
  Input,
  ListItemText,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import { FormControl } from '@mui/material';
import Autocomplete from 'react-google-autocomplete';

export const getTransportByName = (name: string, t: any): string => {
  switch (name) {
    case t('car'): return 'Car';
    case t('train'): return 'Train';
    case t('bicycle'): return 'Bicycle';
    case t('air'): return 'Air';
    default: return '';
  }
}

export const GET_COLUMN = (filtersFromUrl: any, t: any): any => [
  {
    name: 'tripTitle',
    label: t('title'),
    options: {
      display: true,
      viewColumns: false,
      filter: false,
      sort: false,
      filterList: filtersFromUrl.tripTitle ? [filtersFromUrl.tripTitle] : []
    }
  },
  {
    name: 'createdOn',
    label: t('date_of_creation'),
    options: {
      filter: true,
      sort: true,
      filterType: 'custom' as any,
      sortCompare: (order: string) => {
        return (obj1: { data: moment.MomentInput }, obj2: { data: moment.MomentInput }) => {
          const val1 = moment(obj1.data, 'DD.MM.YYYY HH:mm');
          const val2 = moment(obj2.data, 'DD.MM.YYYY HH:mm');
          return val1.diff(val2) * (order === 'asc' ? -1 : 1);
        };
      },
      customFilterListOptions: {
        render: (v) => {
          if (v[0] && v[1]) {
            return [`${t('from')}: ${v[0]}`, `${t('to')}: ${v[1]}`];
          } else if (v[0]) {
            return `${t('from')}: ${v[0]}`;
          } else if (v[1]) {
            return `${t('to')}: ${v[1]}`;
          }
          return false;
        },
        update: (filterList, filterPos, index) => {
          if (filterPos === 0) {
            filterList[index].splice(filterPos, 1, '');
          } else if (filterPos === 1) {
            filterList[index].splice(filterPos, 1);
          } else if (filterPos === -1) {
            filterList[index] = [];
          }

          return filterList;
        }
      },
      filterList: (() => {
        const list: any = [];
        if (filtersFromUrl.createdOnStart) {
          list[0] = filtersFromUrl.createdOnStart;
        }
        if (filtersFromUrl.createdOnEnd) {
          list[1] = filtersFromUrl.createdOnEnd;
        }
        return list;
      })(),
      filterOptions: {
        names: [],
        logic(date: moment.MomentInput, filters: any[]): boolean {
          const check: any = moment(date, 'DD.MM.YYYY HH:mm');
          const from: any = moment(filters[0], 'YYYY-MM-DD');
          const to: any = moment(filters[1], 'YYYY-MM-DD');
          if (
            filters[0] &&
            filters[1] &&
            check.diff(to, 'days') > 0 &&
            check.diff(from, 'days') < 0
          ) {
            return true;
          } else if (filters[1] && check.diff(to, 'days') > 0) {
            return true;
          } else if (filters[0] && check.diff(from, 'days') < 0) {
            return true;
          }
          return false;
        },
        display: (
          filterList: { [x: string]: any },
          onChange: (arg0: any, arg1: any, arg2: any) => void,
          index: string | number,
          column: any
        ): JSX.Element => {
          return (
            <div>
              <FormLabel>{t('date_of_creation')}</FormLabel>
              <FormGroup row>
                <TextField
                  id='startDate'
                  label={t('start_date')}
                  type='date'
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={filterList[index][0] || ''}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '45%', marginRight: '5%', marginTop: '3%' }}
                />
                <TextField
                  id='endDate'
                  label={t('end_date')}
                  type='date'
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={filterList[index][1] || ''}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '45%', marginRight: '5%', marginTop: '3%' }}
                />
              </FormGroup>
            </div>
          )
        }
      }
    }
  },
  {
    name: 'tripType',
    label: t('trip_type'),
    options: {
      display: false,
      viewColumns: false,
      filter: false,
      filterList: filtersFromUrl.tripType ? [filtersFromUrl.tripType] : []
    }
  },
  {
    name: 'id',
    label: 'id',
    options: {
      display: false,
      viewColumns: false,
      filter: false
    }
  },
  {
    name: 'startDate',
    label: t('travel_start_date'),
    options: {
      filter: true,
      sort: true,
      filterType: 'custom' as any,
      filterList: (() => {
        const list: any = [];
        if (filtersFromUrl.startDateOnStart) {
          list[0] = filtersFromUrl.startDateOnStart;
        }
        if (filtersFromUrl.startDateOnEnd) {
          list[1] = filtersFromUrl.startDateOnEnd;
        }
        return list;
      })(),
      customFilterListOptions: {
        render: (v) => {
          if (v[0] && v[1]) {
            return [`${t('from')}: ${v[0]}`, `${t('to')}: ${v[1]}`];
          } else if (v[0]) {
            return `${t('from')}: ${v[0]}`;
          } else if (v[1]) {
            return `${t('to')}: ${v[1]}`;
          }
          return false;
        },
        update: (filterList, filterPos, index) => {
          if (filterPos === 0) {
            filterList[index].splice(filterPos, 1, '');
          } else if (filterPos === 1) {
            filterList[index].splice(filterPos, 1);
          } else if (filterPos === -1) {
            filterList[index] = [];
          }

          return filterList;
        }
      },
      sortCompare: (order: string) => {
        return (obj1: { data: moment.MomentInput }, obj2: { data: moment.MomentInput }) => {
          const val1 = moment(obj1.data, 'DD.MM.YYYY HH:mm');
          const val2 = moment(obj2.data, 'DD.MM.YYYY HH:mm');
          return val1.diff(val2) * (order === 'asc' ? -1 : 1);
        };
      },
      //sortDirection: 'desc',
      filterOptions: {
        names: [],
        logic(date: moment.MomentInput, filters: any[]): boolean {
          const check: any = moment(date, 'DD.MM.YYYY HH:mm');
          const from: any = moment(filters[0], 'YYYY-MM-DD');
          const to: any = moment(filters[1], 'YYYY-MM-DD');
          if (
            filters[0] &&
            filters[1] &&
            check.diff(to, 'days') > 0 &&
            check.diff(from, 'days') < 0
          ) {
            return true;
          } else if (filters[1] && check.diff(to, 'days') > 0) {
            return true;
          } else if (filters[0] && check.diff(from, 'days') < 0) {
            return true;
          }
          return false;
        },
        display: (
          filterList: { [x: string]: any },
          onChange: (arg0: any, arg1: any, arg2: any) => void,
          index: string | number,
          column: any
        ): JSX.Element => (
          <div>
            <FormLabel style={{ fontSize: 15 }}>{t('travel_start_date')}</FormLabel>
            <FormGroup row>
              <TextField
                id='startDate'
                label={t('start_date')}
                type='date'
                InputLabelProps={{
                  shrink: true
                }}
                value={filterList[index][0] || ''}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%', marginTop: '3%' }}
              />
              <TextField
                id='endDate'
                label={t('end_date')}
                type='date'
                InputLabelProps={{
                  shrink: true
                }}
                value={filterList[index][1] || ''}
                onChange={(event) => {
                  filterList[index][1] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%', marginTop: '3%' }}
              />
            </FormGroup>
          </div>
        )
      }
    }
  },
  {
    name: 'endDate',
    label: t('travel_end_date'),
    options: {
      filter: true,
      sort: true,
      filterType: 'custom' as any,
      filterList: (() => {
        const list: any = [];
        if (filtersFromUrl.endDateOnStart) {
          list[0] = filtersFromUrl.endDateOnStart;
        }
        if (filtersFromUrl.endDateOnEnd) {
          list[1] = filtersFromUrl.endDateOnEnd;
        }
        return list;
      })(),
      customFilterListOptions: {
        render: (v) => {
          if (v[0] && v[1]) {
            return [`${t('from')}: ${v[0]}`, `${t('to')}: ${v[1]}`];
          } else if (v[0]) {
            return `${t('from')}: ${v[0]}`;
          } else if (v[1]) {
            return `${t('to')}: ${v[1]}`;
          }
          return false;
        },
        update: (filterList, filterPos, index) => {
          if (filterPos === 0) {
            filterList[index].splice(filterPos, 1, '');
          } else if (filterPos === 1) {
            filterList[index].splice(filterPos, 1);
          } else if (filterPos === -1) {
            filterList[index] = [];
          }

          return filterList;
        }
      },
      sortCompare: (order: string) => {
        return (obj1: { data: moment.MomentInput }, obj2: { data: moment.MomentInput }) => {
          const val1 = moment(obj1.data, 'DD.MM.YYYY HH:mm');
          const val2 = moment(obj2.data, 'DD.MM.YYYY HH:mm');
          return val1.diff(val2) * (order === 'asc' ? -1 : 1);
        };
      },
      filterOptions: {
        names: [],
        logic(date: moment.MomentInput, filters: any[]): boolean {
          const check: any = moment(date, 'DD.MM.YYYY HH:mm');
          const from: any = moment(filters[0], 'YYYY-MM-DD');
          const to: any = moment(filters[1], 'YYYY-MM-DD');
          if (
            filters[0] &&
            filters[1] &&
            check.diff(to, 'days') > 0 &&
            check.diff(from, 'days') < 0
          ) {
            return true;
          } else if (filters[1] && check.diff(to, 'days') > 0) {
            return true;
          } else if (filters[0] && check.diff(from, 'days') < 0) {
            return true;
          }
          return false;
        },
        display: (
          filterList: { [x: string]: any },
          onChange: (arg0: any, arg1: any, arg2: any) => void,
          index: string | number,
          column: any
        ): JSX.Element => (
          <div>
            <FormLabel style={{ fontSize: 15 }}>{t('travel_end_date')}</FormLabel>
            <FormGroup row>
              <TextField
                id='startDate'
                label={t('start_date')}
                type='date'
                InputLabelProps={{
                  shrink: true
                }}
                value={filterList[index][0] || ''}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%', marginTop: '3%' }}
              />
              <TextField
                id='endDate'
                label={t('end_date')}
                type='date'
                InputLabelProps={{
                  shrink: true
                }}
                value={filterList[index][1] || ''}
                onChange={(event) => {
                  filterList[index][1] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%', marginTop: '3%' }}
              />
            </FormGroup>
          </div>
        )
      }
    }
  },
  {
    name: 'fio',
    label: t('fio_carrier'),
    options: {
      filter: false,
      sort: false,
      filterType: 'textField' as any,
      filterList: filtersFromUrl.fio ? [filtersFromUrl.fio] : []
    }
  },
  {
    name: 'phone',
    label: t('number_carrier'),
    options: {
      filter: true,
      sort: false,
      filterType: 'textField' as any,
      filterList: filtersFromUrl.phoneNumber ? [filtersFromUrl.phoneNumber] : []
    }
  },

  {
    name: 'addressFrom',
    label: t('address_from'),
    options: {
      sort: false,
      filterList: filtersFromUrl.addressFrom ? [filtersFromUrl.addressFrom] : [],
      filterType: 'custom' as any,
      customFilterListOptions: {
        render: (v) => {
          if (v) {
            return [`${v[0].name}`];
          }
          return false;
        },
      },
      filterOptions: {
        logic(actionType, filters,tableData) {
         if(filters.length>0){
          return  tableData[17]!==filters[0].place_id
         }
          return false;
        },
        display: (filterList, onChange, index, column, data) => {
          return (
            <FormControl>
              <FormLabel style={{ fontSize: 15 }}>{t('address_from')}</FormLabel>
              <Input
                fullWidth
                color='secondary'
                style={{ width: '100%' }}
                inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
                  <Autocomplete
                    apiKey={'AIzaSyCL9JBbWUVp27zGmZOUR6cErzH43tXOhGQ'}
                    {...props}
                    defaultValue={filterList[index].length>0?filterList[index][0].name:""}
                    onPlaceSelected={(selected) => {
                      filterList[index].push(selected)
                      onChange(filterList[index], index, column);
                    }}
                    options={{ fields: ['place_id', 'name'] }}
                  />
                )}
              />
            </FormControl>
          );
        }
      }
    }
  },
  {
    name: 'addressTo',
    label: t('address_to'),
    options: {
      sort: false,
      filterList: filtersFromUrl.addressTo ? [filtersFromUrl.addressTo] : [],
      filterType: 'custom' as any,
      customFilterListOptions: {
        render: (v) => {
          if (v) {
            return [`${v[0].name}`];
          }
          return false;
        },
      },
      filterOptions: {
        logic( actionType,filters,tableData) {
         if(filters.length>0){
          return  tableData[18]!==filters[0].place_id
         }
          return false;
        },
        display: (filterList, onChange, index, column, data) => {
          return (
            <FormControl>
              <FormLabel style={{ fontSize: 15 }}>{t('address_to')}</FormLabel>
              <Input
                fullWidth
                color='secondary'
                style={{ width: '100%' }}
                inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
                  <Autocomplete
                    apiKey={'AIzaSyCL9JBbWUVp27zGmZOUR6cErzH43tXOhGQ'}
                    {...props}
                    defaultValue={filterList[index].length>0?filterList[index][0].name:""}
                    onPlaceSelected={(selected) => {
                      filterList[index].push(selected)
                      onChange(filterList[index], index, column);
                    }}
                    options={{ fields: ['place_id', 'name'] }}
                  />
                )}
              />
            </FormControl>
          );
        }
      }
    }
  },
  {
    name: 'transportType',
    label: t('type_transport'),
    options: {
      filterList: filtersFromUrl.transport ? [filtersFromUrl.transport] : [],
      sort: false,
      filterType: 'dropdown',
      responsive: 'standard',
      filterOptions: {
        names: [t('car'), t('train'), t('bicycle'), t('air')]
      },
      customBodyRender: (value: string) => {
        return t(value.toLowerCase());
      },
    }
  },
  {
    name: 'price',
    label: t('sum'),
    options: {
      filter: false,
      sort: false,
      filterType: 'custom' as any,
      filterList: (() => {
        const list: any = [];
        if (filtersFromUrl.sumMin) {
          list[0] = filtersFromUrl.sumMin;
        }
        if (filtersFromUrl.sumMax) {
          list[1] = filtersFromUrl.sumMax;
        }
        return list;
      })(),
      customFilterListOptions: {
        render: (v) => {
          if (v[0] && v[1]) {
            return [`${t('from')}: ${v[0]}`, `${t('to')}: ${v[1]}`];
          } else if (v[0]) {
            return `${t('from')}: ${v[0]}`;
          } else if (v[1]) {
            return `${t('to')}: ${v[1]}`;
          }
          return [];
        },
        update: (filterList, filterPos, index) => {
          if (filterPos === 0) {
            filterList[index].splice(filterPos, 1, '');
          } else if (filterPos === 1) {
            filterList[index].splice(filterPos, 1);
          } else if (filterPos === -1) {
            filterList[index] = [];
          }

          return filterList;
        }
      },
      filterOptions: {
        names: [],
        logic(age, filters) {
          if (filters[0] && filters[1]) {
            return age < filters[0] || age > filters[1];
          } else if (filters[0]) {
            return age < filters[0];
          } else if (filters[1]) {
            return age > filters[1];
          }
          return false;
        },
        display: (filterList, onChange, index, column) => (
          <div>
            <FormLabel style={{ fontSize: 15 }}>Сумма</FormLabel>
            <FormGroup row>
              <TextField
                label={t('from_what')}
                type={'number'}
                value={filterList[index][0] || ''}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%' }}
              />
              <TextField
                label={t('to_what')}
                type={'number'}
                value={filterList[index][1] || ''}
                onChange={(event) => {
                  filterList[index][1] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%' }}
              />
            </FormGroup>
          </div>
        )
      }
    }
  },
  {
    name: 'baggageType',
    label: t('baggage_type'),
    options: {
      filter: false,
      sort: false,
      display: true,
      filterType: 'custom' as any,
      filterList: filtersFromUrl.baggageType ? [filtersFromUrl.baggageType] : [],
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <div>
            {value?.map((element, index) => {
              return `${element}${index !== value.length - 1 ? ', ' : ''}`;
            })}
          </div>
        );
      },
      filterOptions: {
        logic(actionType, filters) {
          const filterList =
            filters &&
            filters.map((element) => {
              return element.replace(',', '');
            });
          const actionTypeList =
            actionType &&
            actionType.map((element) => {
              return element.replace(',', '');
            });
          if (filterList.length > 0) {
            const intersection = filterList.filter((element) => actionTypeList.includes(element));
            return intersection.length !== filterList.length;
          }
          return false;
        },
        display: (filterList, onChange, index, column, data) => {
          const optionValues =
            data[13] &&
            data[13].map((element) => {
              return element.replace(',', '');
            });
          return (
            <FormControl>
              <FormLabel style={{ fontSize: 15 }}>{t('type_baggage')}</FormLabel>
              <Select
                multiple
                value={filterList[index]}
                renderValue={(selected: any) => {
                  const renderItems = selected.map((item) => {
                    return item;
                  });
                  return renderItems.join(', ');
                }}
                onChange={(event) => {
                  filterList[index] = [event.target.value];
                  onChange(filterList[index], index, column);
                }}>
                {optionValues.map((item) => (
                  <MenuItem key={item} value={item}>
                    <Checkbox color='primary' checked={filterList[index].indexOf(item) > -1} />
                    <ListItemText primary={item} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        }
      }
    }
  },
  {
    name: 'offerSum',
    label: 'id',
    options: {
      display: false,
      viewColumns: false,
      filter: false,
      sort: false,
      filterType: 'custom' as any,
      filterList: (() => {
        const list: any = [];
        if (filtersFromUrl.offerSumMin) {
          list[0] = filtersFromUrl.offerSumMin;
        }
        if (filtersFromUrl.offerSumMax) {
          list[1] = filtersFromUrl.offerSumMax;
        }
        return list;
      })(),
      customFilterListOptions: {
        render: (v) => {
          if (v[0] && v[1]) {
            return [`${t('from')}: ${v[0]}`, `${t('to')}: ${v[1]}`];
          } else if (v[0]) {
            return `${t('from')}: ${v[0]}`;
          } else if (v[1]) {
            return `${t('to')}: ${v[1]}`;
          }
          return [];
        },
        update: (filterList, filterPos, index) => {
          if (filterPos === 0) {
            filterList[index].splice(filterPos, 1, '');
          } else if (filterPos === 1) {
            filterList[index].splice(filterPos, 1);
          } else if (filterPos === -1) {
            filterList[index] = [];
          }

          return filterList;
        }
      },
      filterOptions: {
        names: [],
        logic(offers, filters) {
          if (filters[0] && filters[1]) {
            return !offers.find(
              (item) => item.offerSum >= filters[0] && item.offerSum <= filters[1]
            );
          } else if (filters[0]) {
            return !offers.find((item) => item.offerSum >= filters[0]);
          } else if (filters[1]) {
            return !offers.find((item) => item.offerSum >= filters[1]);
          }

          return false;
        },
        display: (filterList, onChange, index, column) => (
          <div>
            <FormGroup>
              <div style={{ width: '200%', height: 1, backgroundColor: 'black' }} />
              <FormLabel
                style={{
                  fontSize: 20,
                  fontWeight: 'bold',
                  width: '100%',
                  marginBottom: 10,
                  marginTop: 20
                }}>
                Поиск оффера
              </FormLabel>
              <FormLabel style={{ fontSize: 15 }}>{t('sum_offer')}</FormLabel>
            </FormGroup>
            <FormGroup row>
              <TextField
                label={t('from_what')}
                type={'number'}
                value={filterList[index][0] || ''}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%' }}
              />
              <TextField
                label={t('to_what')}
                type={'number'}
                value={filterList[index][1] || ''}
                onChange={(event) => {
                  filterList[index][1] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%' }}
              />
            </FormGroup>
          </div>
        )
      }
    }
  },
  {
    name: 'payment',
    label: t('payment_offer'),
    options: {
      display: false,
      viewColumns: false,
      sort: false,
      filterType: 'custom' as any,
      filterList: filtersFromUrl.payment ? [filtersFromUrl.payment] : [],

      filterOptions: {
        logic(offers, filters) {
          if (filters.length > 0) {
            if (offers.length > 0) {
              for (let index = 0; index < offers.length; index++) {
                if (
                  offers[index]?.paymentStatus &&
                  offers[index]?.paymentStatus.includes('Paid') &&
                  filters[0] !== 'Сделана'
                ) {
                  return true;
                } else if (filters[0] === t('done')) {
                  return true;
                } else if (
                  !offers[index]?.paymentStatus &&
                  filters[0] === t('done')
                ) {
                  return true;
                } else if (
                  offers[index]?.paymentStatus &&
                  offers[index]?.paymentStatus.includes('Unpaid') &&
                  filters[0] !== t('not_done')
                ) {
                  return true;
                }
              }
            } else {
              return true;
            }
          }
          return false;
        },
        display: (filterList, onChange, index, column, data) => {
          const optionValues = [t('done'), t('not_done')];
          return (
            <FormControl>
              <div style={{ width: '200%', height: 1, backgroundColor: 'transparent' }} />
              <FormLabel style={{ fontSize: 15 }}>{t('payment_offer')}</FormLabel>
              <Select
                value={filterList[index]}
                renderValue={(selected: any) => {
                  return selected;
                }}
                onChange={(event) => {
                  filterList[index] = [event.target.value];
                  onChange(filterList[index], index, column);
                }}>
                {optionValues.map((item) => (
                  <MenuItem key={item} value={item}>
                    <Checkbox color='primary' checked={filterList[index].indexOf(item) > -1} />
                    <ListItemText primary={item} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        }
      }
    }
  },
  {
    name: 'offerFio',
    label: 'id',

    options: {
      display: false,
      viewColumns: false,
      filter: false,
      sort: true,
      filterList: filtersFromUrl.offerFio ? [filtersFromUrl.offerFio] : [],

      filterType: 'custom' as any,
      customFilterListOptions: {
        render: (v) => {
          if (v[0]) {
            return `${v[0]}`;
          }
          return [];
        },
        update: (filterList, filterPos, index) => {
          if (filterPos === 0) {
            filterList[index].splice(filterPos, 1, '');
          } else if (filterPos === 1) {
            filterList[index].splice(filterPos, 1);
          } else if (filterPos === -1) {
            filterList[index] = [];
          }

          return filterList;
        }
      },
      filterOptions: {
        names: [],

        logic(offers, filters) {
          if (filters[0]) {
            return offers.filter((item) => {
              return item.clientInfoModel.fullUserName.includes(filters[0]);
            });
          }
          return false;
        },
        display: (filterList, onChange, index, column) => (
          <div>
            <FormGroup row>
              <TextField
                label={t('fio_offer')}
                type={'text'}
                value={filterList[index][0] || ''}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '100%' }}
              />
            </FormGroup>
          </div>
        )
      }
    }
  },
  {
    name: 'phoneNumberOffer',
    label: t('number_phone_offer'),
    options: {
      display: false,
      viewColumns: false,
      filter: false,
      sort: true,
      filterList: filtersFromUrl.phoneNumberOffer ? [filtersFromUrl.phoneNumberOffer] : [],

      filterType: 'custom' as any,
      // filterList: [25, 50],
      customFilterListOptions: {
        render: (v) => {
          if (v[0]) {
            return `${v[0]}`;
          }
          return [];
        },
        update: (filterList, filterPos, index) => {
          if (filterPos === 0) {
            filterList[index].splice(filterPos, 1, '');
          } else if (filterPos === 1) {
            filterList[index].splice(filterPos, 1);
          } else if (filterPos === -1) {
            filterList[index] = [];
          }

          return filterList;
        }
      },
      filterOptions: {
        names: [],

        logic(offers, filters) {
          if (filters[0]) {
            return (
              offers.filter((item) => item?.phoneNumber && item.phoneNumber.includes(filters[0]))
                .length === 0
            );
          }
          return false;
        },
        display: (filterList, onChange, index, column) => (
          <div>
            <FormGroup row>
              <TextField
                label={t('number_phone_offer')}
                type={'tel'}
                value={filterList[index][0] || ''}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '100%' }}
              />
            </FormGroup>
          </div>
        )
      }
    }
  },
  {
    name: 'locationFromPlaceId',
    label: 'locationFromPlaceId',
    options: {
      display: false,
      viewColumns: false,
      filter: false
    }
  },
  {
    name: 'locationToPlaceId',
    label: 'locationToPlaceId',
    options: {
      display: false,
      viewColumns: false,
      filter: false
    }
  },
];

export const OPTIONS: any = (t: any) => {
  return {
    print: false,
    download: true,
    filter: true,
    search: false,
    sort: true,
    viewColumns: false,
    selectableRows: 'none',
    responsive: 'simple',
    expandableRows: false,
    onDownload: (buildHead, buildBody, columns, data) => {
      return '\uFEFF' + buildHead(columns) + buildBody(data);
    },
    downloadOptions: {
      filename: 'excel-format.csv',
      separator: ';',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true
      }
    },
    textLabels: {
      body: {
        noMatch: t('noMatch'),
        toolTip: t('sort'),
        columnHeaderTooltip: (column: any) => `${t('sort_by')} ${column.label}`
      },
      pagination: {
        next: t('next_page'),
        previous: t('previous'),
        rowsPerPage: t('rowsPerPage'),
        displayRows: t('a_from')
      },
      toolbar: {
        search: t('search'),
        downloadCsv: t('downloadCsv'),
        print: t('print'),
        viewColumns: t('viewColumn'),
        filterTable: t('filterTable')
      },
      filter: {
        all: t('all'),
        title: t('filters'),
        reset: t('reset')
      },
      viewColumns: {
        title: t('showColumns'),
        titleAria: t('show_hide_table_columns')
      },
      selectedRows: {
        text: t('row_selected'),
        delete: t('delete'),
        deleteAria: t('delete_area')
      }
    }
  };
};
