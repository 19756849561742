import moment from 'moment';
import { returnBaggageType } from '../../models/util';

export const COLUMN = (t: any, id?: any) => [
  {
    name: 'name',
    label: '',
    options: {
      customHeadRender: ({index}) => (
        <th key={index} style={{ borderBottom: '0px solid rgba(224, 224, 224, 1)' }} />
      )
    }
  },
  {
    name: 'data',
    label: '',
    options: {
      customHeadRender: ({index}) => (
        <th key={index} style={{ borderBottom: '0px solid rgba(224, 224, 224, 1)' }} />
      ),
      customBodyRender: (
        value: any,
        tableMeta: any,
        updateValue: (arg0: any) => void
      ): JSX.Element => (
        <div style={{flexDirection:'row',display:'flex',alignItems:"center"}}>
          <>
            {tableMeta.rowIndex === 1 && value === t('car') ?
              (
                <p onClick={() => {
                  window.open(`/cars/details/${id}`, '_blank');
                }}>
                  {value}
                </p>
              ) : tableMeta.rowIndex === 2 ? (
                <p onClick={() => {
                  window.open(`/user/details/${id}`, '_blank');
                }}>
                  {value}
                </p>
              ) : (
                <p>{value}</p>
              )
            }
          </>
        </div>
      )
    }
  }
];
export const createOffers = (detailTrip: any, t: any) => {
  return detailTrip.offers?.map((offer: any) => {
    return {
      fullUserName: `${detailTrip.shifter?.firstName ?? ''} ${detailTrip.shifter?.lastName ?? ''}`,
      description: detailTrip.comment,
      offerStatus: offer.status,
      offerSum: `${offer.price ?? ''} ${detailTrip.currency ?? ''}`,
      payment:
        offer?.paymentStatus &&
        offer?.paymentStatus.includes('Paid')
          ? t('done')
          : t('not_done'),
      phoneNumber: detailTrip.shifter?.phone
    };
  });
};
export const createInventories = (data: any) => {
  return data.map((inventory: any) => {
    return {
      fullUserName: `${inventory.sender?.firstName ?? ''} ${inventory.sender?.lastName ?? ''}`,
      userId: inventory.sender?.id,
      phone: inventory.sender?.phone,
      parcelId: inventory.parcelId
    };
  });
};
export const COMLUMNOFFERS = (t?: any) => [
  {
    name: 'fullUserName',
    label: t('fio')
  },
  {
    name: 'description',
    label: t('description')
  },
  {
    name: 'offerStatus',
    label: t('offer_status')
  },
  {
    name: 'offerSum',
    label: t('sum')
  },
  {
    name: 'payment',
    label: t('payment')
  },
  {
    name: 'phoneNumber',
    label: t('number_phone')
  }
];
export const labels = [
  'Телефонный номер',
  'Фамилия',
  'Имя пользователя',
  'Отчество',
  'Дата рождения',
  'Эл. адрес',
  'Дата регистрации'
];

export const createTripDetail = (
  detail: any,
  baggages: any,
  t: any
): { name: string; data: any }[] => {
  return [
    {
      name: t('title'),
      data: detail.tripTitle
    },
    {
      name: t('date_of_creation'),
      data: moment(detail.createdOnTS).format('DD.MM.YYYY HH:mm').toString()
    },
    {
      name: t('fio_carrier'),
      data: `${detail?.shifter?.firstName ?? ''} ${detail?.shifter?.lastName ?? ''}`.trim()
    },
    {
      name: t('number_carrier'),
      data: detail?.shifter?.phone
    },
    {
      name: t('travel_start_date'),
      data: moment(detail.departureDateTS).format('DD.MM.YYYY HH:mm').toString()
    },
    {
      name: t('travel_end_date'),
      data: moment(detail.arrivalDateTS).format('DD.MM.YYYY HH:mm').toString()
    },
    {
      name: t('address_from'),
      data: detail.addressFrom
    },
    {
      name: t('address_to'),
      data: detail.addressTo
    },

    {
      name: t('type_transport'),
      data: t(detail.transportType.toLowerCase())
    },
    {
      name: t('sum'),
      data: `${detail.price} ${detail.currency}`
    },
    {
      name: t('baggage_type'),
      data: detail?.parcelTypes?.map(
        (item: string, index: number) =>
          returnBaggageType(item, baggages, index) +
          (detail?.parcelTypes.length - 1 === index ? '' : ', ')
      )
    },
    {
      name: t('description'),
      data: detail.tripDescription
    },
    {
      name: t('grade'),
      data: detail.isRated ? t('yes') : t('no')
    },
    {
      name: t('verificationStatus'),
      data: detail.verificationStatus
    },
    
  ];
};
export const OPTIONS: any = (t: any) => {
  return {
    filter: false,
    responsive: 'vertical',
    draggableColumns: {
      enabled: true
    },
    print: false,
    download: false,
    search: false,
    sort: false,
    viewColumns: false,
    selectableRows: 'none',
    pagination: false,
    textLabels: {
      body: {
        noMatch: t('noMatch'),
        toolTip: t('sort'),
        columnHeaderTooltip: (column: any) => `${t('sort_by')} ${column.label}`
      },
      pagination: {
        next: t('next_page'),
        previous: t('previous'),
        rowsPerPage: t('rowsPerPage'),
        displayRows: t('a_from')
      },
      toolbar: {
        search: t('search'),
        downloadCsv: t('downloadCsv'),
        print: t('print'),
        viewColumns: t('viewColumn'),
        filterTable: t('filterTable')
      },
      filter: {
        all: t('all'),
        title: t('filters'),
        reset: t('reset')
      },
      viewColumns: {
        title: t('showColumns'),
        titleAria: t('show_hide_table_columns')
      },
      selectedRows: {
        text: t('row_selected'),
        delete: t('delete'),
        deleteAria: t('delete_area')
      }
    }
  };
};
export const COLUMN_POINTS = (t: any) => [
  {
    label:t('industrialPoint'),
    name:'fullAddress'
  }
]
export const GET_COLUMN = (t: any) => [
  {
    name: 'fullUserName',
    label: t('fio_sender')
  },
  {
    name: 'userId',
    label: t('id_sender'),
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <div style={{ cursor: 'pointer' }}>
            <p
              onClick={() => {
                window.open(`/user/details/${value}`, '_blank');
              }}>
              {value}
            </p>
          </div>
        );
      }
    }
  },
  {
    name: 'parcelId',
    label: t('parcelId_sender'),
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <div style={{ cursor: 'pointer' }}>
            <p
              onClick={() => {
                window.open(`/parcel/details/${value}`, '_blank');
              }}>
              {value}
            </p>
          </div>
        );
      }
    }
  },
  {
    name: 'phone',
    label: t('number_phone_sender')
  }
];
