import moment from 'moment';
import {
  FormGroup,
  FormLabel,
  TextField
} from '@material-ui/core';

export const GET_COLUMN = (filtersFromUrl: any, t): any => [
  {
    name: 'brand',
    label: t('brand'),
    options: {
      filter: true,
      sort: false,
      filterType: 'textField' as any,
      filterList: filtersFromUrl.brand ? [filtersFromUrl.brand] : []
    },
  },
  {
    name: 'model',
    label: t('model'),
    options: {
      filter: true,
      sort: false,
      filterType: 'textField' as any,
      filterList: filtersFromUrl.model ? [filtersFromUrl.model] : []
    },
  },
  {
    name: 'id',
    label: t('id'),
    options: {
      filter: false,
      sort: false,
      display: false,
      viewColumns: false,
    },
  },
  {
    name: 'image',
    label: t('image'),
    options: {
      filter: false,
      sort: false,
      customBodyRender: (
        value: any,
        tableMeta: any,
        updateValue: (arg0: any) => void
      ): JSX.Element => (
        <img src={value} height={'50px'} width={'50px'} alt='some car'/>
      )
    }
  },
  {
    name: 'userId',
    options: {
      display: false,
      filter: false
    }
  },
  {
    name: 'fio',
    label: t('fio_owner'),
    options: {
      filter: true,
      sort: false,
      filterType: 'textField' as any,
      filterList: filtersFromUrl.fio ? [filtersFromUrl.fio] : [],
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <div style={{ cursor: 'pointer' }}>
            <p
              onClick={() => {
                window.open(`/user/details/${tableMeta.rowData[4]}`, '_blank');
              }}>
              {value}
            </p>
          </div>
        );
      }
    }
  },
  {
    name: 'createdOn',
    label: t('date_of_creation'),
    options: {
      filter: true,
      sort: true,
      filterType: 'custom' as any,
      customFilterListOptions: {
        render: (v) => {
          if (v[0] && v[1]) {
            return [`Oт: ${v[0]}`, `До: ${v[1]}`];
          } else if (v[0]) {
            return `Oт: ${v[0]}`;
          } else if (v[1]) {
            return `До: ${v[1]}`;
          }
          return false;
        },
        update: (filterList, filterPos, index) => {
          if (filterPos === 0) {
            filterList[index].splice(filterPos, 1, '');
          } else if (filterPos === 1) {
            filterList[index].splice(filterPos, 1);
          } else if (filterPos === -1) {
            filterList[index] = [];
          }

          return filterList;
        }
      },
      filterList: (() => {
        const list: any = [];
        if (filtersFromUrl.createdOnStart) {
          list[0] = filtersFromUrl.createdOnStart;
        }
        if (filtersFromUrl.createdOnEnd) {
          list[1] = filtersFromUrl.createdOnEnd;
        }
        return list;
      })(),
      sortCompare: (order: string) => {
        return (obj1: { data: moment.MomentInput }, obj2: { data: moment.MomentInput }) => {
          const val1 = moment(obj1.data, 'DD.MM.YYYY HH:mm');
          const val2 = moment(obj2.data, 'DD.MM.YYYY HH:mm');
          return val1.diff(val2) * (order === 'asc' ? -1 : 1);
        };
      },
      filterOptions: {
        names: [],
        logic(date: moment.MomentInput, filters: any[]): boolean {
          const check: any = moment(date, 'DD.MM.YYYY HH:mm');
          const from: any = moment(filters[0], 'YYYY-MM-DD');
          const to: any = moment(filters[1], 'YYYY-MM-DD');
          if (
            filters[0] &&
            filters[1] &&
            check.diff(to, 'days') > 0 &&
            check.diff(from, 'days') < 0
          ) {
            return true;
          } else if (filters[1] && check.diff(to, 'days') > 0) {
            return true;
          } else if (filters[0] && check.diff(from, 'days') < 0) {
            return true;
          }
          return false;
        },
        display: (
          filterList: { [x: string]: any },
          onChange: (arg0: any, arg1: any, arg2: any) => void,
          index: string | number,
          column: any
        ): JSX.Element => (
          <div>
            <FormLabel style={{ fontSize: 15 }}>{t('date_of_creation')}</FormLabel>
            <FormGroup row>
              <TextField
                id='startDate'
                label={t('start_date')}
                type='date'
                InputLabelProps={{
                  shrink: true
                }}
                value={filterList[index][0] || ''}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%', marginTop: '3%' }}
              />
              <TextField
                id='endDate'
                label={t('end_date')}
                type='date'
                InputLabelProps={{
                  shrink: true
                }}
                value={filterList[index][1] || ''}
                onChange={(event) => {
                  filterList[index][1] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%', marginTop: '3%' }}
              />
            </FormGroup>
          </div>
        )
      }
    }
  },
  {
    name: 'verificationStatus',
    label: t('verificationStatus'),
    options: {
      sort: false,
      filterList: filtersFromUrl.verificationStatus ? [filtersFromUrl.verificationStatus] : [],
      filterOptions: {
        names: [t('not_verified'), t('verification_pending'), t('verified'), t('verification_failure')]
      }
    }
  },
];
export const OPTIONS: any = (t: any) => {
  return {
    print: false,
    download: true,
    filter: true,
    search: false,
    sort: true,
    viewColumns: false,
    selectableRows: 'none',
    onDownload: (buildHead, buildBody, columns, data) => {
      return '\uFEFF' + buildHead(columns) + buildBody(data);
    },
    downloadOptions: {
      filename: 'excel-format.csv',
      separator: ';',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true
      }
    },
    textLabels: {
      body: {
        noMatch: t('noMatch'),
        toolTip: t('sort'),
        columnHeaderTooltip: (column: any) => `${t('sort_by')} ${column.label}`
      },
      pagination: {
        next: t('next_page'),
        previous: t('previous'),
        rowsPerPage: t('rowsPerPage'),
        displayRows: t('a_from')
      },
      toolbar: {
        search: t('search'),
        downloadCsv: t('downloadCsv'),
        print: t('print'),
        viewColumns: t('viewColumn'),
        filterTable: t('filterTable')
      },
      filter: {
        all: t('all'),
        title: t('filters'),
        reset: t('reset')
      },
      viewColumns: {
        title: t('showColumns'),
        titleAria: t('show_hide_table_columns')
      },
      selectedRows: {
        text: t('row_selected'),
        delete: t('delete'),
        deleteAria: t('delete_area')
      }
    }
  };
};
