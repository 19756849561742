/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useMemo } from 'react';
import MUIDataTable from 'mui-datatables';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { GET_COLUMN, OPTIONS } from './utils';
import { getVerificationStatus } from '../VerificationRequests/utils';
import { CarsClient, UserClient } from '../../services/api';
import useQuery from '../../hooks/useQuery';
import createCarsFromRowData from 'models/modelFactory/car';
import { getVerificationStatusText, setRowBackColor } from 'models/util';
import moment from 'moment';

const CarsComponent = (props: { data?: Array<any>, paginationData?: any}): JSX.Element => {
  const { t } = useTranslation();
  const TITLE = t("cars");
  const [data, setData] = useState<any>([]);
  const [totalRaws, setTotalRaws] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);

  const [brand, setBrand] = useState<string>();
  const [model, setModel] = useState<string>();
  const [clientFullName, setClientFullName] = useState<string>();
  const [createdOnFrom, setCreatedOnFrom] = useState<number>();
  const [createdOnTo, setCreatedOnTo] = useState<number>();
  const [verificationStatus, setVerificationStatus] = useState<string>();

  const { getPaginatedCars } = CarsClient();
  const { getUserById } = UserClient();
  const history = useHistory();
  const query = useQuery();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = parseInt(queryParams.get('page') as string) || 1;
  const pageSize = parseInt(queryParams.get('pageSize') as string) || 10;

  useEffect(() => {
    (async () => {
      const { items, total, totalPages } = await getPaginatedCars(page, pageSize, null, {
        brand, model, createdOnFrom, createdOnTo, verificationStatus, clientFullName
      });
      const carsData = props?.data ?? items;
      const array = carsData ?? [];
      const updatedData = await Promise.all(array.map(async car => {
        const fio = await getUserById(car.userId);
        return {
          ...car,
          fio: `${fio?.firstName ?? ''} ${fio?.lastName ?? ''} ${fio?.middleName ?? ''}`.trim(),
          verificationStatus: t(getVerificationStatusText(car.verificationStatus)),
        }
      }));
      setTotalRaws(total ?? props.paginationData?.total ?? carsData.length);
      setTotalPages(totalPages);
      setData(createCarsFromRowData(updatedData));
    })();
  }, [props, page, pageSize, brand, model, createdOnFrom, createdOnTo, verificationStatus, clientFullName]);

  const onChangePage = (currentPage: number) => {
    props.paginationData
    ? props.paginationData.setPage(currentPage)
    : history.push(`?page=${currentPage + 1}&pageSize=${pageSize}`);
  };

  const queryObject: any = query.getObject();
  const column = useMemo(() => GET_COLUMN(queryObject,t), []);

  return (
    <React.Fragment>
      <div style={props?.data?{}:{ width: '100%', display: 'flex', justifyContent: 'center', margin: '30px 0' }}>
        <div style={{ width: '90%' }}>
          <MUIDataTable
            title={TITLE}
            data={data}
            columns={[...column]}
            options={{
              ...OPTIONS(t),
              onFilterChange(changedColumn: string, filterList, type, changedColumnIndex) {
                switch (changedColumn) {
                  case 'brand': {
                    query.delete('brand');
                    if (filterList[changedColumnIndex][0]) {
                      query.set('brand', filterList[changedColumnIndex][0]);
                      setBrand(filterList[changedColumnIndex][0]);
                    } else {
                      setBrand(undefined);
                    }
                    break;
                  }
                  case 'model': {
                    query.delete('model');
                    if (filterList[changedColumnIndex][0]) {
                      query.set('model', filterList[changedColumnIndex][0]);
                      setModel(filterList[changedColumnIndex][0]);
                    } else {
                      setModel(undefined);
                    }
                    break;
                  }
                  case 'createdOn': {
                    query.delete('createdOnStart');
                    query.delete('createdOnEnd');

                    if (filterList[changedColumnIndex][0]) {
                      query.set('createdOnStart', filterList[changedColumnIndex][0]);
                      setCreatedOnFrom(moment(filterList[changedColumnIndex][0]).valueOf());
                    } else {
                      query.delete('createdOnStart');
                      setCreatedOnFrom(undefined);
                    }

                    if (filterList[changedColumnIndex][1]) {
                      query.set('createdOnEnd', filterList[changedColumnIndex][1]);
                      setCreatedOnTo(moment(filterList[changedColumnIndex][1]).valueOf());
                    } else {
                      query.delete('createdOnEnd');
                      setCreatedOnTo(undefined);
                    }
                    break;
                  }
                  case 'verificationStatus': {
                    query.delete('verificationStatus');
                    if (filterList[changedColumnIndex][0]) {
                      query.set('verificationStatus', filterList[changedColumnIndex][0]);
                      setVerificationStatus(getVerificationStatus(filterList[changedColumnIndex][0], t));
                    } else {
                      setVerificationStatus(undefined);
                    }
                    break;
                  }
                  case 'fio': {
                    query.delete('clientFullName');
                    if (filterList[changedColumnIndex][0]) {
                      query.set('clientFullName', filterList[changedColumnIndex][0]);
                      setClientFullName(filterList[changedColumnIndex][0]);
                    } else {
                      setClientFullName(undefined);
                    }
                    break;
                  }
                  default:
                    if (changedColumn) {
                      query.delete(changedColumn);
                      if (filterList[changedColumnIndex][0]) {
                        query.set(changedColumn, filterList[changedColumnIndex][0]);
                      }
                    } else if (type === 'reset') {
                      query.delete('brand');
                      query.delete('model');
                      query.delete('createdOnStart');
                      query.delete('createdOnEnd');
                      query.delete('verificationStatus');
                      query.delete('setClientFullName');
                      setBrand(undefined);
                      setModel(undefined);
                      setCreatedOnFrom(undefined);
                      setCreatedOnTo(undefined);
                      setVerificationStatus(undefined);
                      setClientFullName(undefined);
                    }
                    break;
                }
                history.push(`?${query.toString()}`);
              },
              setRowProps: (row) => {
                return {
                  onDoubleClick: () => {
                    history.push(`/cars/details/${row[2]}`);
                  },
                  style: {
                    background: setRowBackColor(row[6])
                  }
                };
              },
              serverSide: true,
              onChangeRowsPerPage: (numberOfRows) => {
                history.push(`?page=1&pageSize=${numberOfRows}`);
              },
              rowsPerPage: props.paginationData?.pageSize ?? pageSize,
              page: props.paginationData?.page || (page - 1),
              onChangePage,
              pagination: props.paginationData?.totalPages > 1 || totalPages > 1,
              rowsPerPageOptions: [5, 10, 25, 50, 100],
              count: props.paginationData?.total ?? totalRaws
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default CarsComponent;
